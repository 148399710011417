var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"flex-column white"},[_c('div',{},[_c('v-data-table',{staticClass:"max-width",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.search.pageSize,"hide-default-footer":"","item-key":"id","no-data-text":"暂无查询数据","loading":_vm.listLoading},scopedSlots:_vm._u([{key:"item.rowIndex",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((index+1)+(_vm.search.pageSize*(_vm.search.pageNo-1)))+" ")]}},{key:"item.systemModule",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.systemModule==1 ? '管理后台' : '业务前台')+" ")]}},{key:"item.statusFlag",fn:function(ref){
var item = ref.item;
return [(item.statusFlag==1)?_c('v-chip',{attrs:{"small":"","dark":"","color":"red","label":""}},[_vm._v("禁用")]):_c('v-chip',{attrs:{"small":"","dark":"","color":"green","label":""}},[_vm._v("启用")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary","title":"详情","size":"18"},on:{"click":function($event){return _vm.loadInfo(item)}}},[_vm._v("mdi-information-outline")])]}}])}),_c('div',{staticClass:"mt-2 pr-2 mb-5 max-width  flex-justify-end"},[_c('pagination',{attrs:{"size":_vm.search.pageSize,"total":_vm.total},on:{"input":function($event){return _vm.getList()},"changePageSize":_vm.changePageSize},model:{value:(_vm.search.pageNo),callback:function ($$v) {_vm.$set(_vm.search, "pageNo", $$v)},expression:"search.pageNo"}})],1)],1),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.info.show),callback:function ($$v) {_vm.$set(_vm.info, "show", $$v)},expression:"info.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v("版本更新详情")]),_c('div',{staticClass:" flex-1 overflow-hidden pt-8  mb-1 px-4"},[_c('div',{staticClass:" flex-0 mb-5 flex-y-center"},[_c('div',{staticClass:"form-title flex-0 flex-shrink-0 flex-justify-end"},[_vm._v("版本号：")]),_c('div',[_vm._v(_vm._s(_vm.info.data.showVersionNumber))])]),_c('div',{staticClass:" flex-0 mb-5 flex-y-center"},[_c('div',{staticClass:"form-title flex-0 flex-shrink-0 flex-justify-end"},[_vm._v("更新日期：")]),_c('div',[_vm._v(_vm._s(_vm.info.data.versionUpdateTime))])]),_c('v-divider'),_c('div',{staticClass:"pa-5 version-editor",domProps:{"innerHTML":_vm._s(_vm.info.data.content)}})],1),_c('v-card-actions',[_c('div',{staticClass:"max-width flex-y-center flex-justify-between"},[_c('div',{staticClass:"flex-1 flex-justify-end"},[_c('v-btn',{attrs:{"width":"80","height":"30","depressed":""},on:{"click":function($event){_vm.info.show = false}}},[_vm._v("取消")])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }