<template>
  <v-layout class="flex-column white">
    <div class="">
      <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="search.pageSize"
          class="max-width"
          hide-default-footer
          item-key="id"
          no-data-text="暂无查询数据"
          :loading="listLoading"
      >
        <!-- <template v-slot:item="{expand,item}"> -->

        <template v-slot:item.rowIndex="{index}">
          {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
        </template>
        <template v-slot:item.systemModule="{item}">
          {{ item.systemModule==1 ? '管理后台' : '业务前台' }}
        </template>
        <template v-slot:item.statusFlag="{item}">
          <v-chip v-if="item.statusFlag==1" small dark color="red" label>禁用</v-chip>
          <v-chip v-else small dark color="green" label>启用</v-chip>
        </template>
        <template v-slot:item.active="{item}">
          <v-icon color="primary" title="详情" size="18" @click="loadInfo(item)">mdi-information-outline</v-icon>
        </template>
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList()" @changePageSize="changePageSize" />
      </div>
    </div>
    <!-- 详情 版本 -->
    <v-dialog v-model="info.show" width="1000">
      <v-card>
        <v-card-title class="font-weight-bold">版本更新详情</v-card-title>
        <div class=" flex-1 overflow-hidden pt-8  mb-1 px-4" style="">
          <div class=" flex-0 mb-5 flex-y-center">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end">版本号：</div>
            <div>{{ info.data.showVersionNumber }}</div>
          </div>
          <div class=" flex-0 mb-5 flex-y-center">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end">更新日期：</div>
            <div>{{ info.data.versionUpdateTime }}</div>
          </div>
          <v-divider />
          <div class="pa-5 version-editor" v-html="info.data.content" />
        </div>
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" height="30" class depressed @click="info.show = false">取消</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 详情 版本 end -->
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {
          statusFlag: '0',
          systemModule: '1'
        }
      },
      total: 0,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '版本号', value: 'showVersionNumber' },
        { text: '更新日期', value: 'versionUpdateTime', sortable: false },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      info: {
        show: false,
        data: {}
      },
      listLoading: false
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    getList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      this.$http.get(this.$api.sysVersionQueryPageList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result.records || []
        this.total = res.result.total
      })
    },
    loadInfo(item) {
      this.$http.get(this.$api.sysVersionQueryById, { data: { id: item.id }}).then(res => {
        this.info.show = true
        this.info.data = res.result || {}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.form-title{
  width:100px;
  color: #777;
}
</style>
<style lang="scss">
.version-editor{
  img{
    max-width: 100% !important;
  }
}
</style>
